.Search_wrap {
  border: 1px solid var(--light-grey);
  display: flex;
  display: -ms-flexbox;
  margin: 40px 0;
  -ms-grid-column: 4;
  -ms-grid-column-span: 6;
  grid-column: 4/10;
  -ms-flex-item-align: center;
  align-self: center;
}

@media (max-width: 1024px) {
  .Search_wrap {
    -ms-grid-column: 2;
    -ms-grid-column-span: 10;
    grid-column: 2/12;
  }
}

.Search_wrap.Search_compact {
  border-color: var(--charcoal-grey);
  border-top: none;
  border-bottom: none;
  margin: 0;
}

.Search_searchIcon {
  font-family: Font Awesome\ 5 Free;
  font-weight: 900;
  font-style: normal;
  font-size: 24px;
  color: var(--charcoal-grey);
  display: block;
  padding: 24px;
  border-right: 1px solid var(--light-grey);
}

.Search_wrap form {
  width: 100%;
  margin-bottom: 0;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.Search_wrap input {
  box-sizing: border-box;
  width: 100%;
  border: none;
  padding: 20px;
  font-size: 16px;
  font-family: MuseoSans, sans-serif;
  background-color: transparent;
}

.Search_wrap input:active,
.Search_wrap input:focus {
  outline: none;
}

.Search_compact .Search_searchIcon {
  font-size: 16px;
  color: var(--input-grey);
  padding: 12px;
  border-right: none;
}

.Search_compact input {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: var(--input-grey);
}

.Search_compact input::-webkit-input-placeholder {
  color: var(--input-grey);
}

.Search_compact input:-ms-input-placeholder,
.Search_compact input::-ms-input-placeholder {
  color: var(--input-grey);
}

.Search_compact input::-webkit-input-placeholder {
  color: var(--input-grey);
}

.Search_compact input:-ms-input-placeholder {
  color: var(--input-grey);
}

.Search_compact input::-ms-input-placeholder {
  color: var(--input-grey);
}

.Search_compact input::placeholder {
  color: var(--input-grey);
}
